import React, { ReactElement } from 'react';

import { useSignIn } from '@foodles/customer-app-lib/authentication/hooks';

import SignIn, { Props as SignInProps } from './SignIn';

type Props = Pick<SignInProps, 'initialValues' | 'signInOrSignUpMode'>;

const ConnectedSignIn = ({
  initialValues,
  signInOrSignUpMode,
}: Props): ReactElement => {
  const { invalidCredentials, inError, signIn } = useSignIn();

  return (
    <SignIn
      initialValues={initialValues}
      signIn={signIn}
      inError={inError}
      invalidCredentials={invalidCredentials}
      signInOrSignUpMode={!!signInOrSignUpMode}
    />
  );
};

export default ConnectedSignIn;
