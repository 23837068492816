import { NextPage, NextPageContext } from 'next';
import React from 'react';

import { redirectTo } from '../../../core/helper/navigation';
import { StatelessPage } from '../../../core/typings';
import { Routes } from '../../../typings';
import * as authenticationService from '../../service';

export interface RedirectLoggedInUserProps {
  shouldRedirect?: boolean;
}

function redirectLoggedInUser<P = {}>(
  WrappedComponent: StatelessPage<P>,
): NextPage<RedirectLoggedInUserProps> {
  const PageComponent: NextPage<RedirectLoggedInUserProps> = (
    props: P & RedirectLoggedInUserProps,
  ): JSX.Element | null => {
    if (props.shouldRedirect) return null;

    return <WrappedComponent {...props} />;
  };

  PageComponent.displayName = `redirectLoggedInUser(${WrappedComponent.displayName})`;
  PageComponent.getInitialProps = async (
    ctx: NextPageContext,
  ): Promise<(P & RedirectLoggedInUserProps) | RedirectLoggedInUserProps> => {
    const shouldRedirect = await authenticationService.isAuthenticated(ctx);
    if (shouldRedirect) {
      await redirectTo(ctx, Routes.HOME);
    }

    return {
      ...(WrappedComponent.getInitialProps
        ? await WrappedComponent.getInitialProps(ctx)
        : {}),
      shouldRedirect,
    };
  };

  return PageComponent;
}

export default redirectLoggedInUser;
